.timesheetDate {
  padding: 1rem;
  border-radius: 24px;
  color:#464646;
  background-color:#FFF;
  border: 1px solid #AEAEAF;
  font-size: 1.5rem;
  font-family: NotoSansJP-500;
  text-align: center;

  @media (max-width: 980px) {
      margin-bottom:1rem;
      width:100%;
  }
}